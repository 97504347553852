<template>
  <section id="Contact" class="mt-12">
    <v-img
      :src="require('@/assets/Home/contact.jpg')"
      class="white--text"
      height="100%"
      gradient="rgba(5, 11, 31, .2), rgba(5, 11, 31, .6)"
    >
      <v-container class="fill-height px-4 my-6">
        <v-responsive
          height="100%"
          width="100%"
        >
        <v-row class="mx-0" justify="center">
          <v-col cols="12" sm="10" md="8" lg="8" xl="6">
            <validation-observer ref="cantact">
              <v-row class="mx-0">
                <v-col cols="12" class="text-center">
                  <span class="title-contact white--text" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">Contáctenos</span>
                </v-col>       
                <v-col cols="12" class="text-center">
                  <p class="info-contact white--text" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
                    ¿No te  gustan los formularios? <br>
                  </p>
                  <p class="info-contact white--text" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
                    <strong style="font-weight: 700">Mundial</strong><br>
                    <span style="font-weight: 700"><img :src="require('@/assets/Home/phone.svg')" alt="" :width="phoneSize.width" :height="phoneSize.height" style="margin: 0 5px"><a href="tel:+34 693 72 06 26" class="link-phone">+34 693 72 06 26</a> </span><br>
                    <strong style="font-weight: 400"><v-icon class="link-phone" style="font-size: 30px;">mdi-email</v-icon> info@todocartas.online</strong>
                  </p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="info-contact white--text" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
                        <strong class="link-phone" style="font-weight: 700">Venezuela</strong><br>
                        <strong style="font-weight: 700"><v-icon class="link-phone" style="font-size: 30px;">mdi-whatsapp</v-icon> <a class="link-phone" href="https://api.whatsapp.com/send?phone=+584247078066&text=Me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n" target="_blank">+58 424-7078066</a></strong><br>
                        <strong style="font-weight: 400"><v-icon class="link-phone" style="font-size: 30px;">mdi-email</v-icon> ve@todocartas.online</strong>
                      </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="info-contact white--text" style="letter-spacing: 0.1em !important" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
                        <strong style="font-weight: 700">Colombia</strong><br>
                        <strong style="font-weight: 700"><v-icon class="link-phone" style="font-size: 30px;">mdi-whatsapp</v-icon> <a class="link-phone" href="https://api.whatsapp.com/send?phone=+573193118387&text=Me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n" target="_blank">+57 3193118387</a></strong><br>
                        <strong style="font-weight: 400"><v-icon class="link-phone" style="font-size: 30px;">mdi-email</v-icon> col@todocartas.online</strong><br>
                      </p> 
                    </v-col>
                  </v-row>
                </v-col>                
              </v-row>
              <v-row class="mx-0 contact" justify="center">
                <v-col cols="12" sm="6" md="6" lg="6" class="pb-0 pt-1">
                  <validation-provider name="Nombres" vid="name" rules="required" v-slot="{ errors }">
                    <v-text-field v-model="contact.name" placeholder="Nombres" name="Nombres" type="text" solo flat :error-messages="errors[0]" class="pb-0" />
                    </v-text-field>
                  </validation-provider>
                </v-col>
                 <v-col cols="12" sm="6" md="6" lg="6" class="pb-0 pt-1">
                  <validation-provider name="Apellidos" vid="last_name" v-slot="{ errors }">
                    <v-text-field v-model="contact.last_name" placeholder="Apellidos" name="Apellidos" type="text" solo flat :error-messages="errors[0]" class="pb-0" />
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="mx-0 contact">
                <v-col cols="12" sm="6" md="6" lg="6" class="pb-0 pt-1">
                  <validation-provider name="Email" vid="email" rules="required|email" v-slot="{ errors }">
                    <v-text-field v-model="contact.email" placeholder="Email" name="Email" type="text" solo flat :error-messages="errors[0]" class="pb-0" />
                    </v-text-field>
                  </validation-provider>
                </v-col>
                 <v-col cols="12" sm="6" md="6" lg="6" class="pb-0 pt-1">
                  <validation-provider name="Móvil" vid="movil" v-slot="{ errors }">
                    <v-text-field v-model="contact.movil" placeholder="Móvil" name="Móvil" type="text" solo flat :error-messages="errors[0]" class="pb-0" />
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row class="mx-0">
                <v-col cols="12" class="pb-0 mb-0">
                  <validation-provider name="Mensaje" vid="message" rules="required" v-slot="{ errors }">
                    <v-textarea
                      name="Mensaje"
                      label="Mensaje"
                      auto-grow
                      rows="5"
                      row-height="37"
                      flat
                      placeholder="Mensaje"
                      v-model="contact.mensaje"
                      solo
                      class="pb-0 mb-0"
                      hide-details
                    ></v-textarea>
                  <div v-if="errors[0]" class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>   
                  </validation-provider> 
                </v-col>
              </v-row>
            </validation-observer>  
            <v-row class="mx-0 contact" justify="center">
              <v-col cols="1" class="d-flex justify-end px-0 mx-0" >
                <v-checkbox off-icon="mdi-checkbox-blank" color="orange" hide-details dense v-model="contact.permission" class="pa-0 mt-md-n1">                 
                </v-checkbox>                
              </v-col>
              <v-col cols="11" sm="9" md="9" lg="9" class="text-center px-0 pb-0">
                    <p class="permission-contact text-center" :class="{'lg-view' : $vuetify.breakpoint.mdAndUp, 'sm-view': $vuetify.breakpoint.smAndDown, 'xl-view': $vuetify.breakpoint.xlOnly}">
                      Otorgo mi concentimiento inequívoco y expreso el tratamiento de mis datos personale conforme a las finalidades informadas. <i>Más información</i></p>                   
              </v-col>
            </v-row>
            <v-row class="mx-0" justify="center">
              <v-col cols="10" sm="6" md="4" lg="4" class="py-0">
                <v-btn :loading="send" @click="validateContact" color="orange lighten-1" class="btn-landing" height="58.93px" x-large block dark :disabled="!contact.permission">Enviar mesaje</v-btn>
              </v-col>
            </v-row>          
          </v-col>
        </v-row>
        </v-responsive>
      </v-container>
    </v-img>
    <base-material-snackbar
      v-model="snackbar.active"
      :type="snackbar.type"
      bottom
    >
      {{ snackbar.text }}
    </base-material-snackbar> 
  </section>
</template>

<script>
  export default {
    name: 'Contact',
    data(){
      return {
        contact:{
          name: '',
          last_name: '',
          email: '',
          movil: '',
          mensaje: '',
          permission: false
        },
        send: false,
        snackbar:{
          text:'',
          active: false,
          type:''
        },
      }
    },
    methods:{
      resetContact(){
        this.contact = {
          name: '',
          last_name: '',
          email: '',
          movil: '',
          mensaje: '',
          permission: false
        };
        this.$refs.cantact.reset();
      },
      validateContact(){
        this.$refs.cantact.validate().then(result => {
          if(result){
            this.sendMessage();
          }
        });
      },
      async sendMessage(){
        this.send = true;
        try{
          const { data } = await this.$api.post('api/contact/email', this.contact);
          this.snackbar = {
            text: data.message,
            type: 'success',
            active: true,
          };
          this.resetContact();
        }
        catch(e){
          this.snackbar = {
            text: 'Lo sentimos, se ha producido un error. Por favor intente más tarde.',
            type: 'error',
            active: true,
          };
        }
        finally{
          this.send = false;
        }
      }
    },
    computed: {
      phoneSize(){
        return {
          width: this.$vuetify.breakpoint.xl ? '24' : this.$vuetify.breakpoint.mdAndUp ? '14' :  '10',
          height: this.$vuetify.breakpoint.xl ?  '24' : this.$vuetify.breakpoint.mdAndUp ? '14' : '10',
        }
      },
    },
  }
</script>
<style lang="sass" scope>
  .contact 
    & .v-text-field.v-text-field--solo .v-input__control
        min-height: 65px
    & .v-icon.v-icon
      color: white
  .title-contact
    font-weight: 700
    line-height: 1em
    font-family: Museo-700 !important
    &.lg-view 
      font-size: 2.2125rem !important
    &.sm-view 
      font-size: 1.4rem !important
      text-align: center !important
    &.xl-view 
      font-size: 2.3125rem !important
  .info-contact
    font-weight: 100
    font-family: Roboto, sans-serif !important
    &.lg-view 
      font-size: 1.225rem !important
    &.sm-view 
      font-size: 1rem !important
      text-align: center
    &.xl-view 
      font-size: 1.6rem !important
  .permission-contact
    font-weight: 400
    font-family: Roboto, sans-serif !important
    color: #ffffff
    &.lg-view 
      font-size: 1.125rem !important
    &.sm-view 
      font-size: 1.0225rem !important
      text-align: center
    &.xl-view 
      font-size: 1.3rem !important
  .link-phone
    text-decoration: none
    font-weight: 400
    color: white !important

</style>


